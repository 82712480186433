import React from 'react'
import '../../css/webDevelopment/potential.css'

export default function Potential() {
    return (
        <div className="androidApp">
            <div className="mainContent" style={{overflow:"hidden"}}>
                <img src="assets/images/webDevelopment/potential.png" alt="" className='image' data-aos="slide-right" />
                <div className="content" data-aos="slide-left">
                    <h1>Unleashing Digital Potential</h1>
                    <p>At Cabem Technologies, we are dedicated to turning your vision into a compelling online reality. With a skilled team of web developers and designers, we go beyond the ordinary, crafting websites that stand out in the digital landscape. <br />Our approach combines innovation and functionality to ensure your website isn't just a digital placeholder but a dynamic platform that engages your audience effectively. We specialize in creating responsive and user-centric designs that adapt seamlessly to different devices, providing an optimal experience for your visitors. <br /><br />From intuitive navigation to visually striking aesthetics, our websites are designed to leave a lasting impression. We believe in the power of a strong online presence, and our commitment is to provide you with a website that not only represents your brand but also drives tangible results.</p>
                </div>
            </div>
            <img src="assets/images/webDevelopment/potentialBg.png" alt="" className="bgImage" />
        </div>
    )
}
