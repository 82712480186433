import React from 'react'
import '../../css/seoMarketing/seoServices.css'

export default function SeoServices() {
    return (
        <>
            <div className="seoServicesBg" style={{overflow:"hidden"}}>
                <img src="assets/images/seoMarketing/seoServicesBg.png" alt="" data-aos="zoom-out" />
            </div>
            <div className="seoServices" style={{overflow:"hidden"}}>
                <div className="content" data-aos="slide-right">
                    <h1>SEO Services</h1>
                    <p>At Cabem Technologies, we offer comprehensive SEO services designed to propel your online presence to new heights. Our dedicated team of SEO professionals employs a strategic and data-driven approach to enhance your website's visibility, drive organic traffic, and boost your rankings on leading search engines. <br />From meticulous keyword research and on-page optimization to content strategy and link building, we cover every aspect of SEO to ensure your website not only attracts but engages your target audience. Our goal is to position your business as a digital authority, driving sustained growth and increasing your competitive edge in the online arena.</p>
                </div>
                <img src="assets/images/seoMarketing/seoServices.png" alt="" data-aos="slide-left" />
            </div>
        </>
    )
}
