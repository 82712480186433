import React from 'react'
import AboutDesc from '../components/aboutPage/AboutDesc'
import OurTeam from '../components/aboutPage/OurTeam'
import OurJourney from '../components/aboutPage/OurJourney'

export default function AboutPage() {
    return (
        <>
            <AboutDesc />
            <OurTeam/>
            <OurJourney/>
        </>
    )
}
