import React from 'react'
import '../css/header.css'
import { Link } from 'react-router-dom'

export default function Header() {

  var navItemStyle = {
    padding: "10px 20px 10px 0px",
  }
  return (
    <header>
      <div className='logoContainer'>
        <div className="logo" data-aos="zoom-in">
          <img src="assets/images/cabem-logo.png" alt="Cabem Technologies" />
          <p>Your Vision, Our Expertise</p>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg" data-aos="zoom-in">
        <div className="container-fluid" style={{ justifyContent: "flex-end" }}>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav" style={{ alignItems: "flex-end" }}>
              <Link className="nav-link" style={navItemStyle} to={"/"}><li>Home</li></Link>
              <Link className="nav-link" style={navItemStyle} to={"/cab-compare-app"}><li>B2C Project</li></Link>
              <li className="nav-item dropdown" style={{ padding: "3px 16px 3px 0px", alignItems: "flex-end", display: "flex", flexDirection: "column" }}>
                <Link className="nav-link dropdown-toggle" to={""} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to={"/mobile-app-developement"}>Mobile App Development</Link></li>
                  <li><Link className="dropdown-item" to={"/web-development"}>Web Development</Link></li>
                  <li><Link className="dropdown-item" to={"/ui-ux-design"}>UI/UX Design </Link></li>
                  <li><Link className="dropdown-item" to={"/digital-marketing"}>Digital Marketing</Link></li>
                  <li><Link className="dropdown-item" to={"/social-media-marketing"}>Social Media Marketing</Link></li>
                  <li><Link className="dropdown-item" to={"/seo-marketing"}>SEO Marketing</Link></li>
                </ul>
              </li>
              <Link className="nav-link" style={navItemStyle} to={"/about-us"}><li>About us</li></Link>
              <Link className="nav-link" style={navItemStyle} to={"/career"}><li>Career</li></Link>
              <Link className="nav-link" style={navItemStyle} to={"/contact-us"}><li>Contact us</li></Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
