import React from 'react'
import '../../css/cabCompareApp/ourPromise.css'

export default function OurPromise() {
  return (
    <div className="ourPromise" style={{overflow:"hidden"}}>
        <div className="firstSide" data-aos="slide-right">
            <img src="assets/images/cabCompareApp/cabskaro-logo.png" alt="" className="logo" />
            <div className="content">
                <h1>Our Promise</h1>
                <p>Cabem Technologies, we're more than just data. We're your partner in seizing the best deals,   yourallin finding the  perfect ride. As a startup, we're drivenby innovation and committed to making your traveldecisions straightforward, affordable, and, most importantly, smart.</p>
            </div>
        </div>
        <img src="assets/images/cabCompareApp/dashboard.png" alt="" className="phoneImg" data-aos="slide-left" />
    </div>
  )
}
