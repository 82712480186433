import React from 'react'
import '../../css/uiuxDesign/softwareSolution.css'

export default function SoftwareSolution() {
  return (
    <div className="softwareSolution" style={{overflow:"hidden"}}>
        <img src="assets/images/uiuxDesign/softwareSolution.png" alt="" data-aos="slide-right" />
        <div className="content" data-aos="slide-left">
            <h1>Future-Forward Software Solutions</h1>
            <p>Step into the future with Cabem Technologies' innovative software solutions. Our expert team designs and develops cutting-edge software that not only meets your current needs but is also scalable for the evolving demands of tomorrow. Experience a new era of efficiency and functionality with our forward-thinking approach to software development</p>
        </div>
    </div>
  )
}
