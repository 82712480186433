import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import '../css/adminData.css'
import { useLocation, useNavigate } from 'react-router-dom';

export default function AdminData() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    async function deleteData(id) {
        const token = process.env.REACT_APP_API_TOKEN;
        const api = process.env.REACT_APP_CONTACT_API;
        setLoading(true);
        await fetch(api, {
            method: "delete",
            headers: {
                'Content-Type': 'application/json',
                token,
                id,
            }
        }).then((res) => {
            return res.json();
        }).then(() => {
            fetchAllData().then((res) => {
                setLoading(false);
                setData(res.data);
            }).catch(() => {
                setLoading(false);
                alert("Please Try Again");
            });
        }).catch(() => {
            setLoading(false);
            alert("Data Deletion Failed");
        })
    }

    async function fetchAllData() {
        const token = process.env.REACT_APP_API_TOKEN;
        const api = process.env.REACT_APP_CONTACT_API;
        const response = await fetch(api, {
            headers: {
                'Content-Type': 'application/json',
                token
            }
        });
        return response.json();
    }
    useEffect(() => {
        if (location.state === null) {
            navigate("/");
        } else {
            setLoading(true);
            fetchAllData().then((res) => {
                setLoading(false);
                setData(res.data);
            }).catch(() => {
                setLoading(false);
                alert("Please Try Again");
            });
        }
    }, [location.state, navigate]);
    return (
        <div className="adminData">
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th>Requirement</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <tr align="center" style={{ height: 200, backgroundColor: 'transparent' }}>
                            <td colSpan={20}>
                                <ReactLoading type='spokes' height={50} width={50} color='green' />
                            </td>
                        </tr>
                            :
                            data.length !== 0 ? data.map(element => <tr key={element._id}>
                                <td>{element.name}</td>
                                <td>{element.email}</td>
                                <td>{element.phone}</td>
                                <td>{element.requirements}</td>
                                <td><button onClick={() => deleteData(element._id)}><i class="fa-solid fa-trash"></i></button></td>
                            </tr>
                            ) : <tr align="center" style={{ height: 50, backgroundColor: 'transparent' }}>
                                <td colSpan={20}>
                                    No data Found
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
