import React from 'react'
import '../../css/cabCompareApp/navigateRide.css'

export default function NavigateRide() {
  return (
    <div className="navigateRide" style={{overflow:"hidden"}}>
        <img src="assets/images/cabCompareApp/recommendation.png" alt="" data-aos="slide-right" />
        <div className="content" data-aos="slide-left">
            <h1>Cabs Karo: Navigating Your Ride</h1>
            <p>Tired of juggling cab booking apps? Let Cabs Karo be your guiding light. We compare prices across India's leading ride-hailing services like Uber, Ola, Rapido, Meru, Indrive, Quick Ride, and more. Your journey begins here, as we ensure that you always get the best deal on wheels.</p>
        </div>
    </div>
  )
}
