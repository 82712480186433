import React from 'react'
import SolutionAbout from '../components/landingPage/SolutionAbout'
import OurServices from '../components/landingPage/OurServices'
import B2CProjects from '../components/landingPage/B2CProjects'
import GetInTouch from '../components/landingPage/GetInTouch'

export default function LandingPage() {
    return (
        <>
            <SolutionAbout />
            <OurServices/>
            <B2CProjects/>
            <GetInTouch/>
        </>
    )
}
