import React from 'react'
import FreeEstimation from '../components/socialMedia/FreeEstimation'
import StrategicMastery from '../components/socialMedia/StrategicMastery'

export default function SocialMedia() {
    return (
        <>
            <FreeEstimation />
            <StrategicMastery/>
        </>
    )
}
