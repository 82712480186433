import React from 'react'
import MobileApp from '../components/mobileAppDevelopement/MobileApp'
import AndroidApp from '../components/mobileAppDevelopement/AndroidApp'
import IPhoneApp from '../components/mobileAppDevelopement/IPhoneApp'

export default function MobileAppDevelopement() {
    return (
        <>
            <MobileApp />
            <AndroidApp />
            <IPhoneApp />
        </>
    )
}
