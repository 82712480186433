import React from 'react'
import '../../css/career/whyCabem.css'

export default function WhyCabem() {
    return (
        <div className="whyCabem">
            <h1>Why Cabem Technologies?</h1>
            <div className="items" style={{overflow:"hidden"}}>
                <div className="item" data-aos="zoom-in-right">
                    <img src="assets/images/career/reason1.png" alt="" />
                    <p>Cutting-Edge Technology: At Cabem Technologies, we don't just follow trends; we set them. Join a team that thrives on innovation, pushing boundaries, and creating solutions that redefine the digital landscape. Here, you'll work with the latest technologies to develop web and mobile solutions that make a real difference.</p>
                </div>
                <div className="item" data-aos="zoom-in">
                    <img src="assets/images/career/reason2.png" alt="" />
                    <p>Digital and Social Impact: Be part of a company that doesn't just build websites and apps but crafts digital experiences. Our digital marketing and social media marketing teams are dedicated to making waves in the online world. Join us in creating impactful campaigns, driving engagement, and shaping brand narratives.</p>
                </div>
                <div className="item" data-aos="zoom-in-left">
                    <img src="assets/images/career/reason3.png" alt="" />
                    <p>Collaborative Culture: We believe in the power of collaboration. As a member of the Cabem family, you'll experience a culture that values teamwork, open communication, and a shared commitment to success. Your ideas matter, and together, we'll achieve greatness.</p>
                </div>
            </div>
        </div>
    )
}
