import React from 'react'
import '../css/chatWithUs.css'
import { Link } from 'react-router-dom'

export default function ChatWithUs() {
    return (
        <Link className="chatWithUs" to={"https://wa.me/8447471061"}>
            <img src="assets/images/whatsApp.png" alt="" />
        </Link>
    )
}
