import React from 'react'
import '../../css/socialMedia/freeEstimation.css'
import { Link } from 'react-router-dom'

export default function FreeEstimation() {
    return (
        <>
            <div className="freeEstimationBg" style={{overflow:"hidden"}}>
                <img src="assets/images/socialMedia/socialBg.png" alt="" data-aos="zoom-out" />
            </div>
            <div className="freeEstimation"style={{overflow:"hidden"}}>
                <div className="content" data-aos="slide-right">
                    <p>Crafting User-Friendly Experiences: <br />Bridging Design and Social Media Marketing</p>
                    <Link to={"/contact-us"}>Get Free Estimation</Link>
                </div>
                <img src="assets/images/socialMedia/freeEstimation.png" alt="" data-aos="slide-left" />
            </div>
        </>
    )
}
