import React, { useState } from 'react'
import '../css/admin.css'
import { useNavigate } from 'react-router-dom';

export default function Admin() {
    const navigate=useNavigate();
    const [code, setCode] = useState("");
    function onSubmit(e){
        e.preventDefault();
        if(code==="vision"){
            navigate("/contact-us-all-fully-secure-posted-data",{state:{code}});
        }else{
            alert("Invalid Code");
            navigate("/");
        }
    }
    return (
        <div className="adminPage">
            <form onSubmit={onSubmit}>
                <h1>Log In</h1>
                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder='Enter The Code' />
                <button type='submit'>Log In</button>
            </form>
        </div>
    )
}
