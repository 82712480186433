import React from 'react'
import CraftingDigital from '../components/uiuxDesign/CraftingDigital'
import SoftwareSolution from '../components/uiuxDesign/SoftwareSolution'

export default function UiUxDesign() {
    return (
        <>
            <CraftingDigital />
            <SoftwareSolution />
        </>
    )
}
