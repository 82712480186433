import React from 'react'
import ExploreOpportunity from '../components/career/ExploreOpportunity'
import WhyCabem from '../components/career/WhyCabem'

export default function Career() {
    return (
        <>
            <ExploreOpportunity />
            <WhyCabem />
        </>
    )
}
