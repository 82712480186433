import React, { useState } from 'react'
import ReactLoading from 'react-loading'
import '../../css/contact/contactForm.css'

export default function ContactForm() {
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        phone: "",
        requirements: "",
    });

    async function onSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if (inputs.name.length === 0 || inputs.email.length === 0 || inputs.phone.length === 0 || inputs.requirements.length === 0) {
            setLoading(false);
            alert("Please Fill All Inputs");
        } else if (inputs.email.indexOf("@") === -1 || inputs.email.indexOf(".") === -1) {
            setLoading(false);
            alert("Please Enter Valid Email");
        } else if (inputs.phone.length !== 10) {
            setLoading(false);
            alert("Please Enter Valid Mobile Number");
        } else {
            const token = process.env.REACT_APP_API_TOKEN;
            const api = process.env.REACT_APP_CONTACT_API;
            await fetch(api, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    token
                },
                body: JSON.stringify(inputs),
            }).then((res) => {
                return res.json();
            }).then((res) => {
                setLoading(false);
                setInputs({
                    name: "",
                    email: "",
                    phone: "",
                    requirements: "",
                });
                alert("Data Submitted Successfully");
            }).catch((e) => {
                setLoading(false);
                alert("Data Submition Failed");
            });
        }
    }
    return (
        <>
            <div className="contactForm" style={{ overflow: "hidden" }}>
                <h1 data-aos="fade-in">Get in Touch</h1>
                <h2 data-aos="fade-in">Fill in the Form below</h2>
                <form action="" data-aos="fade-in" onSubmit={onSubmit}>
                    <div className="name">
                        <label htmlFor="name">Your full name</label>
                        <input type="text" name="name" id="name" value={inputs.name} onChange={(e) => setInputs({ ...inputs, name: e.target.value })} />
                    </div>
                    <div className="email">
                        <label htmlFor="email">Your Email Address</label>
                        <input type="email" name="email" id="email" value={inputs.email} onChange={(e) => setInputs({ ...inputs, email: e.target.value })} />
                    </div>
                    <div className="phone">
                        <label htmlFor="phone">Your Contact Number</label>
                        <input type="number" name="phone" id="phone" value={inputs.phone} onChange={(e) => setInputs({ ...inputs, phone: e.target.value })} />
                    </div>
                    <div className="phone">
                        <label htmlFor="requirement">Your Requirments</label>
                        <textarea name="requirement" id="requirement" cols="30" rows="10" value={inputs.requirements} onChange={(e) => setInputs({ ...inputs, requirements: e.target.value })}    ></textarea>
                    </div>
                    <button type='submit'>{
                        loading ? <div style={{ marginTop: -20, width: "100%", height: "100%" }}>
                            <ReactLoading type='spin' height={"100%"} width={"100%"} />
                        </div> : "Submit"
                    }</button>
                </form>
            </div>
            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d876.6648588762072!2d77.28278846951248!3d28.489801277184004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI5JzIzLjMiTiA3N8KwMTcnMDAuNCJF!5e0!3m2!1sen!2sin!4v1701342883585!5m2!1sen!2sin" width="100%" height="300" style={{ border: "0px" }} loading="lazy" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>
    )
}
