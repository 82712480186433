import React from 'react'
import WebDevelop from '../components/webDevelopment/WebDevelop'
import Potential from '../components/webDevelopment/Potential'

export default function WebDevelopment() {
  return (
    <>
        <WebDevelop/>
        <Potential/>
    </>
  )
}
