import React from 'react'
import SeoServices from '../components/seoMarketing/SeoServices'
import StrategicSeo from '../components/seoMarketing/StrategicSeo'

export default function SeoMarketing() {
    return (
        <>
            <SeoServices />
            <StrategicSeo/>
        </>
    )
}
