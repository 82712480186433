import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutPage from './view/pages/AboutPage';
import LandingPage from './view/pages/LandingPage';
import CabCompareApp from './view/pages/CabCompareApp';
import MobileAppDevelopement from './view/pages/MobileAppDevelopement';
import UiUxDesign from './view/pages/UiUxDesign';
import SeoMarketing from './view/pages/SeoMarketing';
import WebDevelopment from './view/pages/WebDevelopment';
import DigitalMarketing from './view/pages/DigitalMarketing';
import SocialMedia from './view/pages/SocialMedia';
import Career from './view/pages/Career';
import Contact from './view/pages/Contact';
import ScrollToTop from './view/components/ScrollToTop';
import Header from './view/components/Header';
import Footer from './view/components/Footer';
import ChatWithUs from './view/components/ChatWithUs';
import Admin from './view/pages/Admin';
import AdminData from './view/pages/AdminData';

function App() {
  return (
    <BrowserRouter basename='/'>
      <ScrollToTop />
      <ChatWithUs/>
      <Header/>
      <Routes>
        <Route path='/' Component={LandingPage} />
        <Route path='/about-us' Component={AboutPage} />
        <Route path='/cab-compare-app' Component={CabCompareApp} />
        <Route path='/mobile-app-developement' Component={MobileAppDevelopement} />
        <Route path='/web-development' Component={WebDevelopment} />
        <Route path='/ui-ux-design' Component={UiUxDesign} />
        <Route path='/digital-marketing' Component={DigitalMarketing} />
        <Route path='/social-media-marketing' Component={SocialMedia} />
        <Route path='/seo-marketing' Component={SeoMarketing} />
        <Route path='/career' Component={Career} />
        <Route path='/contact-us' Component={Contact} />
        <Route path='/admin' Component={Admin} />
        <Route path='/contact-us-all-fully-secure-posted-data' Component={AdminData} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
