import React from 'react'
import '../../css/webDevelopment/webDevelop.css'

export default function WebDevelop() {
    return (
        <>
            <div className="webDevelopeBg" style={{overflow:"hidden"}}>
                <img src="assets/images/webDevelopment/webDevelopBg.png" alt="" data-aos="zoom-out" />
            </div>
            <div className="webDevelop" style={{overflow:"hidden"}}>
                <div className="content" data-aos="slide-right">
                    <h1>Web Development</h1>
                    <p>Web application development embodies an avant-garde approach to tech-savvy and futuristic design-centric web development. Collaborating with a team of adept web developers and designers, we harness their proficiency to craft top-tier web applications featuring cutting-edge technology interfaces. <br /><br />Our web application development translates into impactful, advanced, and tailor-made web-based software boasting high-end user interfaces and unparalleled flexibility. Immerse yourself in our premium, cost-effective, and resilient web development service, underpinned by extensive market research and an impactful strategy tailored to capture niche markets."</p>
                </div>
                <img src="assets/images/webDevelopment/webDevelop.png" alt="" data-aos="slide-left" />
            </div>
        </>
    )
}
