import React from 'react'
import '../css/footer.css'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer style={{overflow:"hidden"}}>
      <div className="content mainContent" data-aos="fade-up-right">
        <img src="assets/images/cabem-logo.png" alt="Cabem Technologies Pvt. Ltd." />
        <p>Embark on a journey of innovation with Cabem Technologies, your go-to destination for cutting-edge software solutions. Specializing in web and mobile development, digital marketing, and B2B excellence, we're not just creating solutions; we're shaping experiences. <br /> <br />Explore our B2C initiative, revolutionizing travel by comparing cab prices across companies, and stay tuned for more future-forward projects. Join us in redefining possibilities.</p>
        <div className="socialMedia">
          <Link to={""} className="icon">
            <i className="fa-brands fa-facebook-f"></i>
          </Link>
          <Link target='_blank' to={"https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A101250131&keywords=cabem%20technologies%20pvt%20ltd&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=2c7d2c3a-6e99-468e-ba76-0b0e541dddfc&sid=(L)&spellCorrectionEnabled=true"} className="icon">
            <i className="fa-brands fa-linkedin-in"></i>
          </Link>
          <Link target='_blank' to={"https://www.instagram.com/cabemtechnologies/"} className="icon">
            <i className="fa-brands fa-instagram"></i>
          </Link>
          <Link to={""} className="icon">
            <i className="fa-brands fa-x-twitter"></i>
          </Link>
        </div>
      </div>
      <div className="content itServices" data-aos="fade-up">
        <h1>IT Services</h1>
        <div className="services">
          <Link to={"/mobile-app-developement"}>Mobile App Dvelopment</Link>
          <Link to={"/web-development"}>Web Development</Link>
          <Link to={"/ui-ux-design"}>UI/UX Design</Link>
          <Link to={"/digital-marketing"}>Digital Marketing</Link>
          <Link to={"/social-media-marketing"}>Social Media Marketing</Link>
          <Link to={"/seo-marketing"}>SEO Marketing</Link>
        </div>
      </div>
      <div className="content contact" data-aos="fade-up-left">
        <h1>Contact Info</h1>
        <p>H-506, H Block, Sarovar Portico, Surajkund,<br /> Shooting Range Road, sector 41-42, Faridabad, Haryana 121009.</p>
        <div className="contactDetails">
          <Link to={"tel:+91 84474 71061"}><i className="fa-solid fa-phone"></i>+91 84474 71061</Link>
          <Link to={"mailto:support@cabemtechnologies.in"}><i className="fa-solid fa-envelope"></i>support@cabemtechnologies.in</Link>
        </div>
      </div>
    </footer>
  )
}
