import React from 'react'
import '../../css/landingPage/getInTouch.css'
import { Link } from 'react-router-dom'

export default function GetInTouch() {
    return (
        <div style={{overflow:"hidden"}}>
        <div className="getInTouch" data-aos="zoom-out">
            <p>Fuel your business growth with our expert website and software development services</p>
            <Link to={"/contact-us"}>Get in touch</Link>
        </div>
        </div>
    )
}
