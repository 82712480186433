import React from 'react'
import '../../css/aboutPage/aboutDesc.css'

export default function AboutDesc() {
  return (
    <div className="aboutDesc" style={{overflow:"hidden"}}>
        <div className="content firstContent">
            <div className="textContent" data-aos="fade-right">
                <h1>Your journey to smarter travel starts here.</h1>
                <p>Redefining Convenience with Cabem Technologies <br /><br /> Welcome to Cabem Technologies, where innovation meets excellence in the world of software solutions. Established in September 2023, we are a dynamic tech company founded by three passionate partners driven by a shared vision for transformative digital solutions.</p>
            </div>
            <img src="assets/images/aboutPage/journey.png" alt="" data-aos="fade-left" />
        </div>
        <div className="content">
            <img src="assets/images/aboutPage/expertise.png" alt="" data-aos="fade-right" />
            <div className="textContent" data-aos="fade-left">
                <h1>Our Expertise</h1>
                <p>At Cabem Technologies, we specialize in crafting tailored software solutions for both B2B enterprises and B2C initiatives. From cutting-edge web and mobile development to strategic digital marketing, our services are designed to elevate your business and captivate your audience.</p>
            </div>
        </div>
        <div className="content thirdContent">
            <div className="textContent" data-aos="fade-right">
                <h1>Driving Efficiency</h1>
                <p>At Cabem Technologies, we specialize in crafting tailored software solutions for both B2B enterprises and B2C initiatives. From cutting-edge web and mobile development to strategic digital marketing, our services are designed to elevate your business and captivate your audience.</p>
            </div>
            <img src="assets/images/aboutPage/driving.png" alt="" data-aos="fade-left" />
            <img className='drivingbg' src="assets/images/aboutPage/drivingbg.png" alt="" />
        </div>
        <div className="content">
            <img src="assets/images/aboutPage/travel.png" alt="" data-aos="fade-right" />
            <div className="textContent" data-aos="fade-left">
                <h1>Revolutionizing Travel</h1>
                <p>In the B2C realm, we are proud to present a groundbreaking project that compares cab prices across different companies. This initiative reflects our commitment to making travel not only convenient but also cost-effective. As we look to the future, we have ambitious plans to develop similar projects that redefine user experiences across various industries.</p>
            </div>
        </div>
    </div>
  )
}
