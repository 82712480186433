import React from 'react'
import '../../css/cabCompareApp/someText.css'

export default function SomeText() {
  return (
    <div className="someText" style={{overflow:"hidden"}}>
        <div className="first" data-aos="zoom-out">
            <p>Get Ready for Something Extraordinary! Our App is on the Horizon - Coming Soon!</p>
        </div>
        <div className="second" data-aos="zoom-out">
            <p>At Cabem Technologies Pvt Ltd, we're committed to making your choices smart, straightforward, and satisfying. Stay tuned for exciting updates and features as we continue to evolve. Cabem Technologies Pvt Ltd is here to make your life easier, one smart choice at a time.</p>
        </div>
    </div>
  )
}
