import React from 'react'
import '../../css/aboutPage/ourJourney.css'

export default function OurJourney() {
  return (
    <div className="ourJourney" style={{overflow:"hidden"}}>
        <div className="content" data-aos="slide-right">
            <h1>Our Journey</h1>
            <p>The journey of Cabem Technologies is fueled by our founders' unwavering motivation. We believe in pushing boundaries, embracing challenges, and delivering solutions that make a meaningful impact. Our story is one of continuous growth, learning, and a relentless pursuit of excellence.</p>
        </div>
        <img src="assets/images/aboutPage/ourJourney.png" alt="" data-aos="slide-left" />
    </div>
  )
}
