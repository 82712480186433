import React from 'react'
import Banner from '../components/contact/Banner'
import ContactForm from '../components/contact/ContactForm'

export default function Contact() {
    return (
        <>
            <Banner />
            <ContactForm/>
        </>
    )
}
