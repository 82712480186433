import React from 'react'
import '../../css/career/exploreOpportunity.css'

export default function ExploreOpportunity() {
    return (
        <>
            <div className="careerBg" style={{ backgroundImage: "url('assets/images/career/careerBg.png')",overflow:"hidden" }}>
                <div className="content" data-aos="slide-right">
                    <h1>Career</h1>
                    <p>CabemTechnologies / Career</p>
                </div>
            </div>
            <div className="exploreOpportunity" style={{overflow:"hidden"}}>
                <div className="content" data-aos="zoom-out">
                    <div className="first">Join Cabem Technologies and Ignite Your Career in Tech and Marketing! <br /><br />Welcome to Cabem Technologies, where innovation meets excellence. As a dynamic and rapidly growing technology and marketing company, we're on the lookout for talented individuals who are ready to make a significant impact. If you're passionate about web development, mobile application development, digital marketing, social media marketing, and more, Cabem Technologies is the place to be.</div>
                    <h1>Explore Future Opportunities with Cabem Technologies</h1>
                    <div className="second">At Cabem Technologies, we are constantly seeking talented individuals who share our passion for creating innovative solutions in the dynamic world of web and mobile app development. While we currently don't have specific job openings, we are always eager to connect with exceptional professionals.</div>
                </div>
                <img src="assets/images/career/explore.png" alt=""  data-aos="slide-left" />
            </div>
        </>
    )
}
