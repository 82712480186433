import React from 'react'
import OurPromise from '../components/cabCompareApp/OurPromise'
import NavigateRide from '../components/cabCompareApp/NavigateRide'
import SomeText from '../components/cabCompareApp/SomeText'

export default function CabCompareApp() {
    return (
        <>
            <OurPromise/>
            <NavigateRide/>
            <SomeText/>
        </>
    )
}
