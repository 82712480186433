import React from 'react'
import '../../css/uiuxDesign/craftingDigital.css'

export default function CraftingDigital() {
    return (
        <>
            <div className="craftingBg" style={{overflow:"hidden"}}>
                <img src="assets/images/uiuxDesign/uiuxBg.png" alt="" data-aos="zoom-out" />
            </div>
            <div className="craftingDigital">
                <div className="mainContent" style={{overflow:"hidden"}}>
                    <div className="content" data-aos="slide-right">
                        <h1>Crafting Digital Excellence</h1>
                        <p>At Cabem Technologies, our focus on UI/UX design extends beyond websites to create exceptional digital experiences. Collaborating with our skilled design team, we elevate digital interfaces to new heights, ensuring your users are engaged and delighted across various platforms <br />From user-friendly mobile apps to interactive software interfaces, our UI/UX design services are rooted in innovation and functionality. We specialize in developing designs that seamlessly adapt to different devices, providing an immersive and cohesive experience. Let us transform your digital presence into a captivating journey that leaves a lasting impact on your audience</p>
                    </div>
                    <img src="assets/images/uiuxDesign/crafting.png" alt="" className="image" data-aos="slide-left" />
                </div>
                <img src="assets/images/uiuxDesign/craftingBg.png" alt="" className="bgImage" />
            </div>
        </>
    )
}
