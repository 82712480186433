import React from 'react'
import MarketingExcellence from '../components/digitalMarketing/MarketingExcellence'
import ElevateBrand from '../components/digitalMarketing/ElevateBrand'

export default function DigitalMarketing() {
  return (
    <>
      <MarketingExcellence />
      <ElevateBrand />
    </>
  )
}
