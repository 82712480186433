import React from 'react'
import '../../css/socialMedia/strategicMastery.css'

export default function StrategicMastery() {
    return (
        <div className="strategicMastery" style={{overflow:"hidden"}}>
            <img src="assets/images/socialMedia/strategyMastery.png" alt="" data-aos="slide-right" />
            <div className="content" data-aos="slide-left">
                <h1>Strategic Social Media Mastery</h1>
                <p>At Cabem Technologies, we seamlessly integrate user-friendly design principles with impactful social media marketing strategies. Elevate your digital presence with experiences that not only captivate but also drive engagement across social platforms. <br /><br /> "Go beyond likes with our strategic approach to social media marketing. Elevate your brand's online presence and engagement with targeted strategies designed for digital success</p>
            </div>
        </div>
    )
}
