import React from 'react'
import '../../css/contact/banner.css'

export default function Banner() {
    return (
        <div className="banner" style={{backgroundImage:"url('assets/images/contact/contactBg.png')"}}>
            <div className="details" style={{overflow:"hidden"}}>
                <h1 data-aos="slide-right">CONTACT US</h1>
                <h2 data-aos="slide-left">We're Just a Click Away- 24/7</h2>
                <div data-aos="slide-right">
                    <span>+91 84474 71061</span>
                    <span>support@cabemtechnologies.in</span>
                </div>
            </div>
            <div className="letsTalk" data-aos="zoom-in">We will Happy to Answer you</div>
        </div>
    )
}
