import React from 'react'
import '../../css/digitalMarkting/elevateBrand.css'

export default function ElevateBrand() {
    return (
        <div className="elevateBrand" style={{overflow:"hidden"}}>
            <img src="assets/images/digitalMarketing/elevateBrand.png" alt="" data-aos="slide-right" />
            <div className="content" data-aos="slide-left">
                <h1>Elevating Your Brand Online</h1>
                <p>Web application development embodies an avant-garde approach to tech-savvy and futuristic design-centric web development. Collaborating with a team of adept web developers and designers, we harness their proficiency to craft top-tier web applications featuring cutting-edge technology interfaces. <br /><br />Our web application development translates into impactful, advanced, and tailor-made web-based software boasting high-end user interfaces and unparalleled flexibility. Immerse yourself in our premium, cost-effective, and resilient web development service, underpinned by extensive market research and an impactful strategy tailored to capture niche markets."</p>
            </div>
        </div>
    )
}
