import React from 'react'
import '../../css/landingPage/b2c-projects.css'
import { Link } from 'react-router-dom'

export default function B2CProjects() {
    return (
        <div className="b2cProjects" style={{overflow:"hidden"}}>
            <h1><span>B2C</span> <span>Project</span></h1>
            <div className="project" data-aos="slide-left">
                <div className="pro-content">
                    <h2>Cab Compare App Development</h2>
                    <h3>Your journey to smarter travel starts here.</h3>
                    <p>We're revolutionizing service accessibility by bringing different industry service providers  under one platform. No more hustling through multiple sites for different needs - we've got it all. (for home page)</p>
                    <Link to={"/cab-compare-app"}>Read More {">>"}</Link>
                </div>
                <img src="assets/images/landingPage/iPhone15-Pro.png" alt="" className="image" />
            </div>
        </div>
    )
}
