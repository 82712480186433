import React from 'react'
import '../../css/landingPage/our-services.css'
import { Link } from 'react-router-dom'

export default function OurServices() {
    return (
        <section className='our-services' id='services' style={{overflow:"hidden"}}>
            <h1 id='h1class'>Our Services</h1>
            <div className="services">
                <div className="service" data-aos="zoom-in">
                    <Link to={"/mobile-app-developement"}>
                        <img src="./assets/images/landingPage/app-developement.jpg" alt="" />
                        <h2>Mobile App Developement</h2>
                        <p>Robust and remarkable next-generation mobile solutions that are simple and with easy user interface for B2C as wellas heavy enterprise-grade applications to automatecritical business processes.</p>
                    </Link>
                </div>
                <div className="service" data-aos="zoom-in">
                    <Link to={"/web-development"}>
                        <img src="./assets/images/landingPage/web-developement.jpg" alt="" />
                        <h2>Web Development</h2>
                        <p>Crafting robust and exceptional next-generation web solutions with user-friendly interfaces.we specialize in creating web platforms that streamline and automate critical business processes.</p>
                    </Link>
                </div>
                <div className="service" data-aos="zoom-in">
                    <Link to={"/ui-ux-design"}>
                        <img src="./assets/images/landingPage/ui-ux-design.jpg" alt="" />
                        <h2>UI/UX Design</h2>
                        <p>Designing next-generation web solutions with robust functionality and exceptional user interfaces.our expertise in UI/UX ensures seamless experiences that streamline and automate critical business processes.</p>
                    </Link>
                </div>
                <div className="service" data-aos="zoom-in">
                    <Link to={"/digital-marketing"}>
                        <img src="./assets/images/landingPage/digital-marketing.jpg" alt="" />
                        <h2>Digital Marketing</h2>
                        <p>Boost your brand's online visibility with our digital marketingexpertise.Achieve first-page search results, drive web traffic, and enhance your online presence effectively.</p>
                    </Link>
                </div>
                <div className="service" data-aos="zoom-in">
                    <Link to={"/social-media-marketing"}>
                        <img src="./assets/images/landingPage/social-media-marketing.jpg" alt="" />
                        <h2>Social Media Marketing</h2>
                        <p>Strategizing and executing impactful digital marketing campaigns to enhance brand visibility and drive engagement we ensuring effective reach and measurable results for businesses of all scales."</p>
                    </Link>
                </div>
                <div className="service" data-aos="zoom-in">
                    <Link to={"/seo-marketing"}>
                        <img src="./assets/images/landingPage/seo-marketing.jpg" alt="" />
                        <h2>SEO Marketing</h2>
                        <p>Secure a prominent presence on social media platforms with our expertise, ensuring your brand rises to the top. We empower you to boost website traffic, enhance visibility, and elevate your standing in the social media landscape</p>
                    </Link>
                </div>
            </div>
        </section>
    )
}
