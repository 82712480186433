import React from 'react'
import '../../css/landingPage/solution-about.css'
import { Link } from 'react-router-dom'

export default function SolutionAbout() {
    return (
        <section className='solutionAbout' style={{ overflow: "hidden" }}>
            <div className="solutionAboutChild solution">
                <div className="content" data-aos="flip-right">
                    <div className="data">
                        <h2>Providing Industry Leading Solutions for</h2>
                        <p>At Cabem Technologies, we specialize in delivering cutting-edge solutions tailored to meet the unique needs of your business. Our commitment to excellence and innovation sets us apart as your trusted partner in navigating the digital landscape.</p>
                    </div>
                    <a href="#services">Learn More</a>
                </div>
                <div className="image" data-aos="zoom-in">
                    <img src="./assets/images/landingPage/solution-landing.jpg" alt="Providing Industry Leading Solutions for" />
                </div>
            </div>
            <div className="solutionAboutChild about">
                <div className="image" data-aos="zoom-in">
                    <img src="./assets/images/landingPage/about-landing.jpg" alt="About Us" />
                </div>
                <div className="content" data-aos="flip-left">
                    <h2>About Us</h2>
                    <p>Welcome to Cabem Technologies, where innovation meets excellence in the world of software solutions. Established in September 2023, we are a dynamic tech company founded by three passionate partners driven by a shared vision for transformative digital solutions.</p>
                    <Link to={"/about-us"}>Learn More</Link>
                </div>
                <img className="backImg" src="./assets/images/landingPage/sol-about-landing.png" alt="" />
            </div>
        </section>
    )
}
