import React from 'react'
import '../../css/aboutPage/ourTeam.css'

export default function OurTeam() {
  return (
    <div className="ourTeam" style={{overflow:"hidden"}}>
        <h1>Our Team</h1>
        <div className="team">
            <div className="member" data-aos="zoom-in">
                <div className="image chahat">
                    <img src="assets/images/aboutPage/chahat.png" alt="" />
                </div>
                <div className="name">CHAHAT BHATIA</div>
                <div className="post">DIRECTOR</div>
            </div>
            <div className="member" data-aos="zoom-in">
                <div className="image ashish">
                    <img src="assets/images/aboutPage/ashish.png" alt="" />
                </div>
                <div className="name">ASHISH UPADHYAY</div>
                <div className="post">DIRECTOR</div>
            </div>
            <div className="member" data-aos="zoom-in">
                <div className="image pramod">
                    <img src="assets/images/aboutPage/pramod.png" alt="" />
                </div>
                <div className="name">PRAMOD PANDIT</div>
                <div className="post">APP / WEB DEVELOPER</div>
            </div>
            <div className="member" data-aos="zoom-in">
                <div className="image sufad">
                    <img src="assets/images/aboutPage/sufad.png" alt="" />
                </div>
                <div className="name">SUFAD M</div>
                <div className="post">LEAD APP DEVELOPER</div>
            </div>
        </div>
    </div>
  )
}
