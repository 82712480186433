import React from 'react'
import '../../css/seoMarketing/strategicSeo.css'

export default function StrategicSeo() {
    return (
        <>
            <div className="strategicSeoTitle">
                <div className="title" data-aos="flip-down">Strategic SEO</div>
            </div>
            <div className="strategicSeo" style={{overflow:"hidden"}}>
                <img src="assets/images/seoMarketing/strategicSeo.png" alt="" data-aos="slide-right" />
                <div className="content" data-aos="slide-left">
                    <p>At Cabem Technologies, we specialize in strategic SEO mastery, offering a comprehensive suite of services to propel your brand to the forefront of online visibility. Our seasoned SEO team combines expertise with innovation, implementing advanced strategies to optimize your website, boost organic traffic, and secure high-ranking positions on major search engines. <br /> <br />From meticulous keyword research to on-page optimization and authoritative link-building, our approach is tailored to maximize your digital footprint. We believe in the transformative power of strategic SEO, providing not just increased visibility but sustainable growth for your business.</p>
                </div>
            </div>
        </>
    )
}
